<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12" lg="3" v-for="(card,index) in top_cards" :key="index">
        <v-card class="pa-5 white--text" :color="card.type">
          <v-row>
            <v-col cols="3">
              <v-icon class="text-h2 my-3" color="white">{{card.icon}}</v-icon>
            </v-col>
            <v-col cols="9">
              <span class="text-h5 font-weight-bold">{{card.title}}<br><span v-if="card.id==='person_card'" class="text-h4 font-weight-bold">{{cardData[`${card.id}`]}}</span><span v-else><span class="text-h5 font-weight-bold">{{cardData[`${card.id}`]['pkr']}} pkr</span><br><span class="text-h5 font-weight-bold">{{cardData[`${card.id}`]['riyal']}} riyal</span></span></span>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-card class="pa-5">
          <span class="text-h4 my-5">Latest transactions</span>
        <v-data-table :headers="fields" :items="transactionList" hide-default-footer mobile-breakpoint="0" @click:row="filterReport">
          <template v-slot:[`item.amount`]="{ item }"><span :class="`${item.type==='received'?'green':'red'}--text`">{{item.type==='received'?'+':'-'}}{{item.amount}}</span></template>
          <template v-slot:[`item.type`]="{ item }"><span>{{item.type.charAt(0).toUpperCase()}}{{item.type.slice(1)}}</span></template>
          <template v-slot:[`item.currency`]="{ item }"><span>{{item.currency.toUpperCase()}}</span></template>
        </v-data-table>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-overlay :value="loading" color="white">
          <v-progress-circular
            indeterminate
            size="64"
            color="primary"
          ></v-progress-circular>
        </v-overlay>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import {eventBus} from '../../../../main'
import {get_dashboard_details} from '@api/dashboard'
  export default {
    data(){
      return{
        fields:[
          {
            text:"Person Name",
            value:"person_name",
          },
          {
            text:"Amount",
            value:"amount",
          },
          {
            text:"Type",
            value:"type",
          },
          { 
            text:"Date",
            value:"date",
          },
          { 
            text:"Time",
            value:"time",
          },
          {
            text:"Currency",
            value:"currency"
          }
        ],
        items:[
        ],
        top_cards:[
          { 
            title:"Total People",
            id:"person_card",
            type:"warning",
            value:"10",
            icon:"mdi-account-multiple-outline",
          },
          { 
            title:"Total Balance",
            id:"debit_card",
            type:"success",
            value:"1000 pkr",
            icon:"mdi-credit-card-outline",
          },
          { 
            title:"Total Recieved",
            id:"received_card",
            type:"primary",
            value:"1000pkr",
            icon:"mdi-cash-multiple",
          },
          { 
            title:"Total Pending",
            id:"pending_card",
            type:"error",
            value:"0pkr",
            icon:"mdi-clock-outline",
          },
        ],
        transactionList:[],
        cardData:{
          person_card:0,
          debit_card:{
            pkr:0,
            riyal:0
          },
          received_card:{
            pkr:0,
            riyal:0
          },
          pending_card:{
            pkr:0,
            riyal:0
          },
        },
        errResp:'',
        loading: false
      }
    },
    mounted(){
      this.getDashboardDetails();
    },
    methods:{
      getDashboardDetails(){
        this.loading= true;
        this.errResp=''
        get_dashboard_details().then(resp=>{
          this.loading= false;
          if(resp.status === 'Success'){
            this.transactionList=resp.data.transactions.reports;
            this.cardData.person_card=resp.data.person_card;
            this.cardData.received_card=resp.data.received_card;
            this.cardData.debit_card=resp.data.debit_card;
            this.cardData.pending_card= resp.data.pending_card;
          }else{
            this.errResp=resp.errors
          }
        })
      },
      filterReport(data){
        eventBus.$emit('filterRep',data.person_id);
        this.$router.push('/statement');
      }
    }
  }
</script>
